import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { ChevronRightIcon } from '@heroicons/react/outline'



export default function Sidebar() {
  return (

            <div className="bg-white ">


                    <div className="flex flex-col flex-grow bg-green-500 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h3 className="text-xl font-bold text-white sm:text-2xl">
                        <span className="block">Dubois Expertise</span>
                        </h3>
                        <p className="mt-4 text-lg leading-6 text-green-200">
                          Contactez Matthieu Dubois pour une expertise fissures indépendante
                        </p>
                        <p className="mt-4 text-lg leading-6 text-green-200">
                          <a href="https://www.dubois-expertise.fr/" target="_blank" rel="noreferrer">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/Fissuration-dubois-expertise.jpg"
                              alt="Fissuration"
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-green-200">
                          <a href="https://www.dubois-expertise.fr/" className="underline" target="_blank" rel="noreferrer">
                            En savoir plus
                          </a>
                        </p>
                    </div>


                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Media Kit Koudepouce
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Vous êtes un pro de l’après sinistre ?
                          </p>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Bandeau, encart promotionnel, publi-information... <br />
                          <a 
                            href="/contact/"
                            className="text-green-500 underline"
                          >
                            Renseignez-vous pour apparaître sur nos supports digitaux
                          </a>
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Solutions en cas de fissures
                          </h3>
                          <ul className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Expertises indépendante</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Etude de sol</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Reprise en sous-oeuvre</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Renforcement structurel</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Embellissement</li>
                          </ul>

                          <a
                            href="/maison/fissures-maison/contact/"
                            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 sm:w-auto"
                            >
                            Trouvez un pro près de chez vous
                            </a>
                        </div>
                      </div>
                    </div>

                    
                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Articles et liens
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <a 
                              href="/maison/fissures-maison/aide/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Comment se faire aider...
                            </a>
                            <a 
                              href="/maison/fissures-maison/interpreter-fissures-maison/reagir/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Savoir quoi faire en cas de fissures
                            </a>
                            <a 
                              href="/maison/fissures-maison/reconnaitre-secheresse/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Cause des fissures
                            </a>
                            <a 
                              href="/maison/fissures-maison/evaluer-danger-fissuration/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Evaluation du risque fissuration
                            </a>
                            <a 
                              href="/maison/fissures-maison/reparer-maison-fissure/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Travaux de réparation
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    

                    
            </div>



        )
    }